<!--@Time : 2022/6/1 18:45-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
  <div>
    404
  </div>
</template>

<script>
export default {
  name: "404"
}
</script>

<style scoped>

</style>